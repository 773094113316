import * as React from 'react';
import styled from 'styled-components';
import themes from '../../themes';

const StyledSvg = styled.svg`
  width: calc(${themes.minimumSpacing} * 6);
  height: calc(${themes.minimumSpacing} * 6);
`;

const StyledPath = styled.path`
  fill: ${themes.colors.midBlack};
`;

const User = () => (
  <StyledSvg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>User icon</title>
    <StyledPath d="M3.73669 22.5H20.2633C20.9592 22.5 21.5059 22.3529 21.9036 22.0586C22.3012 21.7721 22.5 21.3695 22.5 20.8507C22.5 20.115 22.2556 19.333 21.7669 18.5044C21.2781 17.6759 20.574 16.9054 19.6544 16.193C18.7432 15.4729 17.6414 14.8883 16.3491 14.4392C15.0568 13.9823 13.603 13.7539 11.9876 13.7539C10.3805 13.7539 8.93077 13.9823 7.63846 14.4392C6.34615 14.8883 5.24024 15.4729 4.32071 16.193C3.40947 16.9054 2.70947 17.6759 2.22071 18.5044C1.74024 19.333 1.5 20.115 1.5 20.8507C1.5 21.3695 1.69882 21.7721 2.09645 22.0586C2.49408 22.3529 3.04083 22.5 3.73669 22.5ZM12 11.698C12.903 11.698 13.7355 11.4696 14.4976 11.0127C15.2598 10.5559 15.8686 9.94027 16.3243 9.16593C16.7882 8.38385 17.0201 7.50498 17.0201 6.52931C17.0201 5.58462 16.7882 4.73285 16.3243 3.974C15.8686 3.20741 15.2598 2.60343 14.4976 2.16206C13.7355 1.72069 12.903 1.5 12 1.5C11.1053 1.5 10.2769 1.72456 9.51479 2.17367C8.75266 2.62279 8.13965 3.23064 7.67574 3.99723C7.22012 4.75608 6.99231 5.60785 6.99231 6.55254C6.99231 7.52046 7.22012 8.39159 7.67574 9.16593C8.13965 9.94027 8.74852 10.5559 9.50237 11.0127C10.2645 11.4696 11.097 11.698 12 11.698Z" />
  </StyledSvg>
);

export default User;
